import React from 'react';
import PropTypes from 'prop-types';

const RightArrow = ({ className }) => (
  <svg className={className} viewBox="0 0 32 29">
    <g
      stroke="#FF4501"
      strokeWidth={3.9}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M30 11.607H10c-4.418 0-8 3.454-8 7.714v7.715" />
      <path d="M20 1.964l10 9.643-10 9.643" />
    </g>
  </svg>
);

export default RightArrow;

RightArrow.propTypes = {
  className: PropTypes.string,
};
