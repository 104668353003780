import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Waypoint from '../common/Waypoint';
import Button from '../common/Button';
import Section from '../common/Section';
import Grid from '../common/Grid';
import { H1, P } from '../common/Typography';
import RoguesGallery from './RoguesGallery';

import { fadeIn } from '../../utils/mixins';

const Inner = styled.div`
  grid-column: span 12;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.orangeDark};
  display: flex;
  position: relative;
  overflow: hidden;
  @media (max-width: 999px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 60px 5% 160px;
  }
  @media (min-width: 1000px) {
    padding: 60px 5%;
    justify-content: space-between;
  }
`;

const Background = styled(Img)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Spiel = styled.div`
  ${fadeIn()}
  z-index: 1;
  @media (max-width: 999px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 1000px) {
    width: 50%;
  }
`;

const MoreButton = styled(Button)`
  @media (max-width: 999px) {
    position: absolute;
    bottom: 60px;
  }
`;

export default function OurTeamPreview({ members }) {
  return (
    <Waypoint
      render={({ visible }) => (
        <Section>
          <Grid>
            <Inner active={visible}>
              <StaticQuery
                query={graphql`
                  query {
                    texture: file(
                      relativePath: { eq: "our-team-texture.jpg" }
                    ) {
                      childImageSharp {
                        fluid(maxWidth: 1440, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Background
                    fluid={data.texture.childImageSharp.fluid}
                    role="presentation"
                    fadeIn={false}
                  />
                )}
              />
              <Spiel active={visible}>
                <H1>Our team</H1>
                <P>
                  Experts in their field eager to use technology for people and
                  issues that need it the most.
                </P>
                <MoreButton color="white" to="/about">
                  More about us
                </MoreButton>
              </Spiel>
              <RoguesGallery active={visible} members={members} />
            </Inner>
          </Grid>
        </Section>
      )}
    />
  );
}

OurTeamPreview.propTypes = {
  members: PropTypes.array.isRequired,
};
