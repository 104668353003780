import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { fluidRange } from 'polished';

import { fadeIn } from '../../utils/mixins';

const Wrapper = styled.div`
  ${fadeIn()}
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  @media (max-width: 999px) {
    margin-right: ${({ members }) => (members - 1) * 16}px;
  }
`;

const Avatar = styled(Img)`
  border: 5px solid ${({ theme }) => theme.colors.orangeDark};
  position: relative;
  right: -${({ i, total }) => (total - 1 - i) * 16}px;
  transition: transform 0.5s ease-in-out;
  ${({ active }) =>
    active
      ? css`
          transform: translateX(0);
        `
      : css`
          transform: translateX(-${({ i, total }) => (total - 1 - i) * 16}px)
            translateX(${({ i, total }) => `${(total - 1 - i) * 100}%`});
        `}
  border-radius: 50%;
  ${fluidRange(
    {
      prop: 'width',
      fromSize: '60px',
      toSize: '120px',
    },
    '380px',
    '1400px'
  )};
`;

export default function RoguesGallery({ active, members }) {
  return (
    <Wrapper members={members.length} active={active}>
      {members.map((member, i) => (
        <Avatar
          active={active}
          key={i}
          i={i}
          alt={member.node.data.name.text}
          total={members.length}
          fluid={member.node.data.avatar.localFile.childImageSharp.fluid}
        />
      ))}
    </Wrapper>
  );
}

RoguesGallery.propTypes = {
  active: PropTypes.bool.isRequired,
  members: PropTypes.array.isRequired,
};
