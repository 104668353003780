import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import UnstyledButton from '../common/UnstyledButton';
import RightArrow from '../icons/RightArrow';

const Wrapper = styled(UnstyledButton)`
  color: ${({ theme }) => theme.colors.orangeDark};
  background: ${({ theme }) => theme.colors.white};
  height: 160px;
  width: 160px;
  border-radius: 50%;
  transition: background 0.2s ease, transform 0.1s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:hover {
    transform: scale(1.125);
  }
  &:active {
    transform: scale(0.975);
  }
  @media (max-width: 499px) {
    height: 44px;
    width: 44px;
  }
  @media (min-width: 500px) {
    height: 60px;
    width: 60px;
  }
  @media (min-width: 800px) {
    height: 80px;
    width: 80px;
  }
  @media (min-width: 1000px) {
    height: 150px;
    width: 150px;
  }
`;

const Arrow = styled(RightArrow)`
  transition: transform 0.2s ease ${({ rotate }) => (rotate ? 0.75 : 0)}s;
  transform: ${({ rotate }) => `rotate(${rotate ? -180 : 0}deg)`};
  @media (max-width: 999px) {
    width: 18px;
  }
  @media (min-width: 1000px) {
    width: 28px;
  }
`;

const Text = styled.span`
  width: 69%;
  margin-top: 16px;
  text-align: center;
  line-height: 1em;
  font-size: 22px;
  @media (max-width: 999px) {
    display: none;
  }
`;

export default function NextButton({ className, onClick, flipArrow }) {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Arrow rotate={flipArrow} />
      <Text>Keep it going</Text>
    </Wrapper>
  );
}

NextButton.propTypes = {
  className: PropTypes.string,
  flipArrow: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
