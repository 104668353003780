/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import styled from 'styled-components';

import Grid from '../common/Grid';
import Section from '../common/Section';
import { H1, P } from '../common/Typography';

const Title = styled(H1)`
  text-align: center;
  @media (max-width: 999px) {
    grid-column: span 12;
  }
  @media (min-width: 1000px) {
    grid-column: 3 / span 8;
  }
`;

const Spiel = styled(P)`
  text-align: center;
  grid-column: 2 / span 10;
  @media (max-width: 799px) {
    grid-column: span 12;
  }
  @media (min-width: 800px) {
    grid-column: 3 / span 8;
  }
  @media (min-width: 1000px) {
    grid-column: 4 / span 6;
  }
`;

const Statement = () => (
  <Section>
    <Grid>
      <Title>
        We work on
        <br />
        socially and environmentally
        <br />
        responsible projects
      </Title>
      <Spiel>
        Branding and digital are our forte. We put our skills to good use by
        contributing to progress and innovation that have real impact on
        important issues.
      </Spiel>
    </Grid>
  </Section>
);

export default Statement;
