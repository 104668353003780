import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Waypoint from '../common/Waypoint';
import Button from '../common/Button';
import Grid from '../common/Grid';
import CircularSpan from '../common/CircularSpan';
import Section from '../common/Section';
import { H2, P } from '../common/Typography';

import { fadeIn } from '../../utils/mixins';

import video from '../../videos/homepage.mp4';
import placeholder from '../../videos/homepage.jpg';

const Video = styled.video`
  transition: all ease 1s;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transform: ${({ active, theme }) =>
    active
      ? `rotate(${theme.tilt}deg) scale(1)`
      : `rotate(-${theme.tilt}deg) scale(.9)`};
  box-shadow: -2em 2em 5em #9912025c;
  width: 78%;
  @media (max-width: 799px) {
    grid-column: span 12;
    margin: 0 auto 60px;
    grid-row: -1;
  }
  @media (min-width: 800px) {
    margin-left: 18%;
    grid-column: span 6;
  }
`;

const Spiel = styled.div`
  ${fadeIn()};
  @media (max-width: 799px) {
    grid-column: span 12;
  }
  @media (min-width: 800px) {
    grid-column: span 6;
  }
`;

export default function About({ collectiveSize }) {
  return (
    <Waypoint
      render={({ visible }) => (
        <Section color="orange">
          <Grid>
            <Spiel active={visible}>
              <H2>
                First+Foremost is a collective of&nbsp;
                <CircularSpan active={visible}>{collectiveSize}</CircularSpan>
                &nbsp;strategy, design and development experts.
              </H2>
              <P>
                We joined forces based on our common ambition to support and
                promote altruistic progress. We exist to develop brands,
                websites and digital products for clients and projects that
                deserve attention.
              </P>
              <Button color="orange" to="/about">
                More about us
              </Button>
            </Spiel>
            <Video
              active={visible}
              src={video}
              poster={placeholder}
              autoPlay
              muted
              playsInline
              loop
            />
          </Grid>
        </Section>
      )}
    />
  );
}

About.propTypes = {
  collectiveSize: PropTypes.number,
};
