import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';

import { fluidRange } from 'polished';
import Grid from '../common/Grid';
import { P } from '../common/Typography';
import UnstyledList from '../common/UnstyledList';
import UnstyledButton from '../common/UnstyledButton';
import Arrow from '../icons/Arrow';

const Wrapper = styled.div``;

const List = styled(UnstyledList)`
  @media (max-width: 999px) {
    grid-column: span 12;
  }
  @media (min-width: 1000px) {
    grid-column: span 6;
  }
`;

const Item = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.peach}`};
  &:last-child {
    border-bottom: ${({ theme }) => `1px solid  ${theme.colors.peach}`};
  }
`;

const Title = styled.div`
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '36px',
      toSize: '80px',
    },
    '380px',
    '1400px'
  )};
  line-height: 1.4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.div`
  @media (max-width: 999px) {
    overflow: hidden;
    transition: max-height 0.5s linear;
    max-height: ${({ active }) => (active ? '600px' : 0)};
  }
  @media (min-width: 1000px) {
    position: absolute;
    right: 0;
    top: -6px;
    width: 40.2%;
    transition: opacity 0.25s linear;
    opacity: ${({ active }) => (active ? 1 : 0)};
    pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  }
`;

const DescriptionInner = styled.div`
  @media (max-width: 999px) {
    margin-top: 10px;
  }
`;

const Toggle = styled(UnstyledButton)`
  width: 100%;
  outline: none;
`;

const Indicator = styled(Arrow)`
  transition: opacity 0.25s ease, transform 0.25s ease;
  ${fluidRange(
    {
      prop: 'width',
      fromSize: '24px',
      toSize: '55px',
    },
    '380px',
    '1400px'
  )};
  @media (max-width: 999px) {
    transform: rotate(${({ active }) => (active ? 90 : 0)}deg);
  }
  @media (min-width: 1000px) {
    opacity: ${({ active }) => (active ? 1 : 0)};
    transform: translateX(${({ active }) => (active ? 0 : -16)}px);
  }
`;

export default function ServiceExplorer({ className, items }) {
  const [activeIndex, setactiveIndex] = useState(0);
  return (
    <Wrapper className={className}>
      <Grid style={{ position: 'relative' }}>
        <List>
          {items.map((item, i) => (
            <Item key={i}>
              <Toggle onClick={() => setactiveIndex(i)}>
                <Title>
                  {item.name.text}
                  <Indicator active={activeIndex === i} />
                </Title>
              </Toggle>
              <Description active={activeIndex === i}>
                <DescriptionInner>
                  {Parser(item.description.html, {
                    replace: domNode => {
                      if (domNode.name === 'p') {
                        return <P>{domNode.children[0].data}</P>;
                      }
                      return null;
                    },
                  })}
                </DescriptionInner>
              </Description>
            </Item>
          ))}
        </List>
      </Grid>
    </Wrapper>
  );
}

ServiceExplorer.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};
