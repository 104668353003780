import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import { fluidRange } from 'polished';

import Waypoint from '../common/Waypoint';
import Grid from '../common/Grid';
import Section from '../common/Section';
import { H2 } from '../common/Typography';
import Slide from './Slide';
import NextButton from './NextButton';

import { fadeIn } from '../../utils/mixins';

const SLIDES = [
  {
    title: 'Waste',
    image: 'waste',
    spiel: `Working with agencies can be expensive, and sometimes the money isn’t well spent. As a collective of freelancers working directly with clients, we’re able to offer transparent rates and processes.`,
  },
  {
    title: 'Fame',
    image: 'fame',
    spiel: `We don’t do cool stuff for the sake of it, for a week of fame, or to impress our peers. We produce work that makes sense, that’s simple to use and that can last for a while.`,
  },
  {
    title: 'Lies',
    image: 'lies',
    spiel: `We don't cover up the truth with misleading visuals and messages. That's why we exclusively work for clients and projects that have a strong sustainable mission at their core.`,
  },
  {
    title: 'Cliches',
    image: 'cliches',
    spiel: `Audiences shouldn't be treated as masses that all think and behave in the same way. We promise work that's inclusive and that embraces a diversity of perspectives.`,
  },
];

const Wrapper = styled(Section)`
  display: grid;
  @media (max-width: 799px) {
    grid-gap: 40px;
  }
  background: ${({ theme }) => theme.colors.orangeDark};
  position: relative;
`;

const Title = styled(H2)`
  ${fadeIn()}
  grid-column: span 12;
  color: white;
  margin: 0;
  ${fluidRange(
    {
      prop: 'margin-bottom',
      fromSize: '0px',
      toSize: '60px',
    },
    '380px',
    '1400px'
  )};
`;

const NextSlideButton = styled(NextButton)`
  position: absolute;
  bottom: 5vw;
  right: 5%;
  outline: none;
`;

const Slides = styled(SwipeableViews)`
  ${fadeIn()}
  transition-delay: 0.5s;
`;

export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(0);
  let speed = 0;
  if (typeof window !== `undefined`) {
    speed = window.innerWidth > 800 ? 1 : 0.5;
  }

  const setNextSlide = () => {
    return slideIndex === SLIDES.length - 1
      ? setSlideIndex(0)
      : setSlideIndex(slideIndex + 1);
  };

  return (
    <Waypoint
      render={({ visible }) => (
        <Wrapper>
          <Grid>
            <Title active={visible}>What you won’t get with us:</Title>
          </Grid>
          <Slides
            active={visible}
            index={slideIndex}
            disabled
            slideStyle={{ overflow: 'visible', position: 'initial' }}
            springConfig={{
              duration: `${speed}s`,
              easeFunction: 'ease',
              delay: '0s',
            }}
          >
            {SLIDES.map((slide, i) => (
              <Slide
                speed={speed}
                key={i}
                title={slide.title}
                spiel={slide.spiel}
                image={slide.image}
                active={i === slideIndex}
              />
            ))}
          </Slides>
          <NextSlideButton
            onClick={setNextSlide}
            flipArrow={slideIndex === SLIDES.length - 1}
          />
        </Wrapper>
      )}
    />
  );
}
