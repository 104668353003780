import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { fluidRange } from 'polished';

const Wrapper = styled.div`
  position: relative;
`;

const Text = styled.span`
  position: absolute;
  top: 30%;
  left: -2%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0 18%;
  transform: scale(${({ active }) => (active ? 1 : 0.5)}) rotate(8deg);
  transition: opacity 0.25s linear 0.5s,
    transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
  opacity: ${({ active }) => (active ? 1 : 0)};
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '24px',
      toSize: '28px',
    },
    '600px',
    '1400px'
  )};
`;

const Svg = styled.svg`
  width: 100%;
`;

const Line = styled.path`
  stroke-dasharray: ${({ length }) => length}px;
  stroke-dashoffset: ${({ length, active }) => (active ? 0 : length)}px;
  transition: all ease 0.5s;
`;

export default function Badge({ active, className, children }) {
  const $path = useRef(null);
  const [pathLength, setPathLength] = useState(0);
  useEffect(() => {
    setPathLength($path.current.getTotalLength());
  });
  return (
    <Wrapper className={className}>
      <Svg viewBox="0 0 351 243" fill="none">
        <Line
          active={active}
          ref={$path}
          length={pathLength}
          d="M14.213 101.696c-3.359 27.351 11.443 54.458 38.418 76.172 26.962 21.702 65.965 37.9 110.622 43.383 44.658 5.483 86.421-.798 117.832-15.333 31.427-14.542 52.347-37.264 55.705-64.614 3.358-27.351-11.443-54.459-38.419-76.172-26.961-21.702-65.964-37.9-110.622-43.383-44.658-5.483-86.42.798-117.831 15.333C38.49 51.624 17.57 74.346 14.212 101.696z"
          stroke="#FF4501"
          strokeWidth={2}
        />
      </Svg>
      <Text active={active}>{children}</Text>
    </Wrapper>
  );
}

Badge.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
};
