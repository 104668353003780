import styled from 'styled-components';

const UnstyledButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-align: left;
  color: currentColor;
`;

export default UnstyledButton;
