import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Grid from '../common/Grid';
import { P } from '../common/Typography';

import { fadeIn } from '../../utils/mixins';

const Wrapper = styled(Grid)`
  display: grid;
  ${fluidRange(
    {
      prop: 'grid-row-gap',
      fromSize: '20px',
      toSize: '60px',
    },
    '320px',
    '1400px'
  )};
`;

const Title = styled.h3`
  ${fluidRange(
    {
      prop: 'font-size',
      fromSize: '55px',
      toSize: '200px',
    },
    '380px',
    '1400px'
  )};
  grid-column: 1 / span 12;
  z-index: 100;
  margin: 0;
  align-self: end;
  line-height: 0.8em;
  transform: translateX(${({ active }) => (active ? '0%' : '-30%')});
  transition: transform ${({ speed }) => speed}s
      cubic-bezier(0.215, 0.61, 0.355, 1) ${({ speed }) => speed / 10}s,
    color ${({ speed }) => speed / 2}s linear ${({ speed }) => speed}s;
  @media (max-width: 799px) {
    grid-column: 1 / span 10;
    grid-row: 1;
    color: ${({ active }) => (active ? 'white' : 'rgba(255,255,255,.5)')};
  }
  @media (min-width: 800px) {
    color: ${({ active }) => (active ? 'white' : 'rgba(0,0,0,0)')};
    -webkit-text-stroke: 2px white;
    grid-column: 1 / span 12;
    grid-row: 1;
  }
`;

const Spiel = styled(P)`
  color: ${({ theme }) => theme.colors.white};
  ${({ speed }) => fadeIn({ speed, delay: speed })}
  @media (max-width: 799px) {
    grid-column: 1 / span 10;
    grid-row: 2;
  }
  @media (min-width: 800px) {
    grid-column: 1 / span 6;
    grid-row: 2;
  }
`;

const Illustration = styled(Img)`
  grid-column: 7 / span 4;
  z-index: -1;
  ${({ speed }) => fadeIn({ speed, delay: speed })}
  @media (max-width: 799px) {
    grid-row: 1;
    grid-column: 6 / span 5;
  }
  @media (min-width: 800px) {
    grid-row: 1 / span 2;
    grid-column: 7 / span 4;
  }
`;

export default function Slide({ active, speed, title, spiel, image }) {
  return (
    <Wrapper>
      <Title active={active} speed={speed}>
        {title}
      </Title>
      <Spiel speed={speed} active={active}>
        {spiel}
      </Spiel>
      <StaticQuery
        query={graphql`
          query {
            sliderImages: allFile(
              filter: {
                extension: { regex: "/(jpg)/" }
                relativeDirectory: { eq: "promises" }
              }
            ) {
              edges {
                node {
                  name
                  childImageSharp {
                    fluid(maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const slideImage = data.sliderImages.edges.find(
            edge => edge.node.name === image
          );
          if (!slideImage) {
            return null;
          }
          return (
            <Illustration
              placeholderStyle={{ display: 'none' }}
              fluid={slideImage.node.childImageSharp.fluid}
              fadeIn={false}
              role="presentation"
              speed={speed}
              active={active}
              alt={title}
            />
          );
        }}
      />
    </Wrapper>
  );
}

Slide.propTypes = {
  active: PropTypes.bool.isRequired,
  speed: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  spiel: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
