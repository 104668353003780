import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

import Waypoint from '../common/Waypoint';
import Marquee from '../common/Marquee';
import SeekingText from '../icons/Seeking';

import { fadeIn } from '../../utils/mixins';

const Wrapper = styled.section`
  position: relative;
  ${fadeIn()}
  @media (max-width: 1099px) {
    height: 37vw;
  }
  @media (min-width: 1100px) {
    min-height: 500px;
    display: flex;
    align-items: center;
    height: 100vh;
  }
  @media (min-width: 1400px) {
    min-height: 650px;
  }
`;

const LeavesWrapper = styled.div`
  overflow: hidden;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  position: absolute;
`;

const Leaves = styled(Img)`
  @media (max-width: 599px) {
    width: 200%;
    left: -50%;
  }
`;

export default function Hero() {
  return (
    <StaticQuery
      query={graphql`
        query {
          leaves: file(relativePath: { eq: "leaves-seeking.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Waypoint
          render={({ visible }) => (
            <Wrapper active={visible}>
              <Marquee width={[275, 200]}>
                <SeekingText />
              </Marquee>
              <LeavesWrapper>
                <Leaves
                  fadeIn={false}
                  fluid={data.leaves.childImageSharp.fluid}
                  placeholderStyle={{ display: 'none' }}
                  role="presentation"
                />
              </LeavesWrapper>
            </Wrapper>
          )}
        />
      )}
    />
  );
}
