import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import About from '../components/home/About';
import Hero from '../components/home/Hero';
import Statement from '../components/home/Statement';
import OurTeamPreview from '../components/home/OurTeamPreview';
import Slider from '../components/home/Slider';
import PreFooter from '../components/common/PreFooter';
import Services from '../components/home/Services';
import Layout from '../components/common/Layout';
import SEO from '../components/common/Seo';

export default function Index({ data }) {
  return (
    <Layout>
      <SEO title="Ethical branding and digital services" />
      <Hero />
      <Statement />
      <About collectiveSize={data.allPrismicTeamMember.edges.length} />
      <Slider />
      <Services services={data.prismicHome.data.services} />
      <OurTeamPreview members={data.allPrismicTeamMember.edges} />
      <PreFooter />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    prismicHome {
      data {
        services {
          description {
            html
          }
          name {
            text
          }
        }
      }
    }
    allPrismicTeamMember {
      edges {
        node {
          id
          data {
            name {
              text
            }
            avatar {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 100
                    maxHeight: 100
                    cropFocus: CENTER
                    quality: 80
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

Index.propTypes = {
  data: PropTypes.object.isRequired,
};
