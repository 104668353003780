import React from 'react';

const Seeking = () => (
  <svg viewBox="0 0 1420 262">
    <title>Seeking</title>
    <path
      d="M117.5 100.6c47.25 12.95 95.9 28.35 95.9 81.9 0 47.95-36.4 79.1-99.05 79.1-65.1 0-114.1-32.2-114.1-87.85h63c0 26.6 23.1 36.4 51.1 36.4 23.45 0 36.05-9.1 36.05-21.7 0-19.95-26.95-24.5-62.3-35C45.05 140.85 7.25 122.3 7.25 76.1c0-54.6 42.7-75.95 96.6-75.95 58.45 0 104.3 30.8 106.05 81.55h-63c-2.8-18.9-18.9-30.1-43.05-30.1-18.9 0-33.6 5.95-33.6 19.95 0 15.4 15.75 20.3 47.25 29.05zm170.975 1.05h109.2v51.45h-109.2v51.45h128.8V256h-191.8V5.75h189.7V57.2h-126.7v44.45zm206.675 0h109.2v51.45h-109.2v51.45h128.8V256h-191.8V5.75h189.7V57.2h-126.7v44.45zm143.675-95.9h63v101.5l87.15-101.5h73.5L767.275 116l98.7 140h-73.5l-65.45-94.85-25.2 28.7V256h-63V5.75zM870 5.75h63V256h-63V5.75zM954.175 256V5.75h63l87.5 148.75h.7V5.75h63V256h-63l-87.5-148.75h-.7V256h-63zM1352.3 88.7c-4.55-17.15-16.1-37.1-50.75-37.1-41.3 0-57.05 32.2-57.05 79.1 0 47.25 15.75 79.45 59.5 79.45 29.05 0 49.35-17.5 50.05-41.65h-47.6V123h112.7v133H1374l-6.3-31.85h-.7c-11.9 22.75-35.35 37.45-72.1 37.45-71.05 0-113.4-55.3-113.4-130.9 0-75.25 42.35-130.55 121.1-130.55 68.6 0 106.75 40.6 112.7 88.55h-63z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Seeking;
