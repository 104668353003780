import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Badge from '../common/Badge';
import Grid from '../common/Grid';
import Section from '../common/Section';
import { H2 } from '../common/Typography';
import Waypoint from '../common/Waypoint';
import ServiceExplorer from './ServiceExplorer';

import { fadeIn } from '../../utils/mixins';

const Wrapper = styled(Section)`
  display: grid;
  grid-row-gap: 100px;
`;

const MultilingualBadge = styled(Badge)`
  ${fadeIn()}
  position: absolute;
  width: 320px;
  width: 25%;
  min-width: 300px;
  top: -25%;
  right: 0;
  @media (max-width: 799px) {
    display: none;
  }
`;

const Introduction = styled(Grid)`
  position: relative;
`;

const Title = styled(H2)`
  ${fadeIn()}
  @media (max-width: 599px) {
    grid-column: span 12;
  }
  @media (min-width: 600px) {
    grid-column: span 6;
  }
`;

const Explorer = styled(ServiceExplorer)`
  ${fadeIn({ delay: 0.5 })}
`;

export default function Services({ services }) {
  return (
    <Waypoint
      render={({ visible }) => (
        <Wrapper>
          <Introduction>
            <Title active={visible}>
              We can help from the creation or refresh of a brand, through to
              its development on digital channels.
            </Title>
            <MultilingualBadge active={visible}>
              We work in English et Français!
            </MultilingualBadge>
          </Introduction>
          <Explorer items={services} active={visible} />
        </Wrapper>
      )}
    />
  );
}

Services.propTypes = {
  services: PropTypes.array.isRequired,
};
